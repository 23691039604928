import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const UploadVideo = ({ onProjectCreated, setLoading }) => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [loading, setIsLoading] = useState(false);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [fileName, setFileName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [warningModalVisible, setWarningModalVisible] = useState(false); // Nouvel état pour le modal d'avertissement
  const [confirmOverwrite, setConfirmOverwrite] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null); // Stocker le fichier sélectionné
  const [warningMessage, setWarningMessage] = useState(""); // État pour le message du modal d'avertissement
  const [resolution, setResolution] = useState(""); // État pour la résolution
  const [invalidProjectModalVisible, setInvalidProjectModalVisible] =
    useState(false);
  const API_URL = process.env.REACT_APP_API_HOST;

  console.log(API_URL);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file ? file.name : "");
    setSelectedFile(file); // Stocker le fichier dans l'état
  };
  console.log(API_URL);
  // Vérifier si le projet existe
  const checkProjectExists = async () => {
    try {
      const response = await axios.post(`${API_URL}/checkProject.php`, {
        projectName: projectName,
      });
      return response.data.exists;
    } catch (error) {
      setError("Erreur lors de la vérification du projet");
      return false;
    }
  };

  // Supprimer le projet si confirmé
  const deleteProject = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/deleteProjectByProjectName.php`,
        { projectName }
      );
      if (response.data.status === 200) {
        console.warn(
          `Projet existant '${projectName}' supprimé pour être remplacé...`
        );
      } else {
        throw new Error(response.data.message || "Échec de la suppression.");
      }
    } catch (error) {
      console.error("Erreur:", error);
      setError(error.message || "Une erreur s'est produite.");
    }
  };

  // Gestion de l'upload avec vérification et suppression si nécessaire
  const handleUpload = async (e) => {
    e.preventDefault();

    // Validation du nom du projet
    if (!validateProjectName(projectName)) {
      setInvalidProjectModalVisible(true);
      return;
    }

    if (!selectedFile || !projectName) {
      setError(
        "Veuillez sélectionner un fichier vidéo et entrer le nom du projet."
      );
      return;
    }

    // Vérifier si le projet existe déjà
    const projectExists = await checkProjectExists();
    if (projectExists && !confirmOverwrite) {
      setShowModal(true); // Afficher le modal si le projet existe
      return;
    }

    // Si confirmation de l'écrasement, supprimer le projet avant l'upload
    if (confirmOverwrite && projectExists) {
      await deleteProject(); // On attend que le projet soit supprimé
    }

    // Processus d'upload
    const formData = new FormData();
    formData.append("videoFile", selectedFile);
    formData.append("project", projectName);
    formData.append("resolution", resolution); // Ajouter la résolution aux données du formulaire

    setLoading(true);
    setIsLoading(true);

    setIsUploadComplete(false);
    setError(null);

    try {
      // 1. Upload du fichier vidéo
      const uploadResponse = await axios.post(
        `${API_URL}/upload.php`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
        }
      );

      if (uploadResponse.data.status === 200) {
        console.log(`${uploadResponse.data.message}`);
      } else {
        console.error(`${uploadResponse.data.message}`);
        throw new Error(
          uploadResponse.data.message || "Erreur lors de l'upload"
        );
      }

      // 2. Traitement de la vidéo uploadée
      const cropResponse = await axios.post(`${API_URL}/cropVideo.php`, {
        projectName: projectName,
      });
      if (cropResponse.status === 200) {
        console.log("Splitting videos (OK) = ", cropResponse.status);
        setIsUploadComplete(true);
        // Signaler au parent que le projet a été créé
        if (onProjectCreated) {
          onProjectCreated();
        }
        navigate(`/video-list/${projectName}`); //!FIXXX
      } else if (cropResponse.status === 201) {
        console.warn(
          `Aucune scène détectée dans la vidéo et le projet ${projectName} a été supprimé.`
        );
        setWarningMessage(
          `Aucune scène détectée dans la vidéo et le projet ${projectName} a été supprimé.`
        );
        setWarningModalVisible(true);
        if (onProjectCreated) {
          onProjectCreated();
        }
      } else {
        console.error("Erreur de découpage des scènes vidéo");
        throw new Error("Erreur de découpage des scènes vidéo");
      }
    } catch (error) {
      setError(error.message || "Erreur inattendue");
      console.error("Erreur:", error);
    } finally {
      setLoading(false);
      setIsLoading(false); // Utiliser l'état local
      setConfirmOverwrite(false); // Réinitialiser l'état d'écrasement
    }
  };

  // Confirmation de l'écrasement
  const handleOverwriteConfirm = async () => {
    setConfirmOverwrite(true); // Mettre à jour l'état de confirmation
    setShowModal(false);

    // Supprimer le projet
    await deleteProject(); // On attend que le projet soit supprimé

    // Vérifier si le fichier est toujours sélectionné et le projet est défini
    if (selectedFile && projectName) {
      await handleUpload({ preventDefault: () => {} }); // Relancer l'upload
    } else {
      setError("Le fichier vidéo ou le nom du projet est manquant.");
    }
  };

  const closeNoScenesWarning = () => {
    setShowModal(false); //* Fermer le modal si ouvert
    setProgress(0); //* Réinitialiser la barre de progression
    setLoading(false); //* Réinitialiser l'état de chargement
    setIsUploadComplete(false); //* Réinitialiser l'état d'upload
    setFileName(""); //* Réinitialiser le nom du fichier
    setProjectName(""); //* Réinitialiser le nom du projet
    setError(null); //* Réinitialiser les erreurs
    setSelectedFile(null); //* Réinitialiser le fichier sélectionné
    setConfirmOverwrite(false); //* Réinitialiser l'état d'écrasement
    setWarningModalVisible(false);
  };

  const resetForm = () => {
    setShowModal(false); //* Fermer le modal si ouvert
    setProgress(0); //* Réinitialiser la barre de progression
    setLoading(false); //* Réinitialiser l'état de chargement
    setIsUploadComplete(false); //* Réinitialiser l'état d'upload
    setFileName(""); //* Réinitialiser le nom du fichier
    setProjectName(""); //* Réinitialiser le nom du projet
    setError(null); //* Réinitialiser les erreurs
    setSelectedFile(null); //* Réinitialiser le fichier sélectionné
    setConfirmOverwrite(false); //* Réinitialiser l'état d'écrasement
    setWarningModalVisible(false);
    document.getElementById("videoUploadForm").reset(); //* Réinitialiser le formulaire
  };

  // Validation du nom du projet (lettres, chiffres, tirets, et underscores uniquement)
  const validateProjectName = (name) => {
    const regex = /^[a-zA-Z0-9_-]+$/; // Permet uniquement les lettres, chiffres, tirets et underscores
    return regex.test(name);
  };

  // const handleProjectNameChange = (e) => {
  //   const name = e.target.value;
  //   setProjectName(name);

  //   if (!validateProjectName(name)) {
  //     setInvalidProjectModalVisible(true);
  //   }
  // };

  const closeInvalidProjectModal = () => {
    setInvalidProjectModalVisible(false);
  };

  const handleProjectNameBlur = (e) => {
    const name = e.target.value;
    setProjectName(name);

    if (!validateProjectName(name)) {
      setInvalidProjectModalVisible(true);
    } else {
      setInvalidProjectModalVisible(false); // Fermer le modal si le nom est valide
    }
  };

  return (
    <div className="container mt-5">
      {/* <h2 className="text-center">Split Video</h2> */}
      <div className="row">
        <div className="col-md-6 mx-auto pt-3">
          {loading ? (
            <div id="loading" className="mt-3 text-center">
              {/* <div className="spinner-border" role="status"></div>
              <p>Processus en cours...</p> */}
            </div>
          ) : (
            <>
              {error && <div className="alert alert-danger">{error}</div>}
              <form id="videoUploadForm" onSubmit={handleUpload}>
                <div className="form-group">
                  <label htmlFor="project">
                    <small>
                      <strong>
                        Nom du projet&nbsp;
                        <span style={{ color: "yellow" }}>*&nbsp;</span>:
                      </strong>
                    </small>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="project"
                    name="project"
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    onBlur={handleProjectNameBlur} // Gestion de la perte de focus avec validation
                    placeholder="Nom du projet"
                    required
                    autoComplete="off" // !Désactiver les suggestions
                  />
                </div>
                <div className="form-group">
                  <div className="input-group input-group-sm">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="videoFile"
                        name="videoFile"
                        accept="video/*"
                        onChange={handleFileChange}
                        required
                      />
                      <label className="custom-file-label" htmlFor="videoFile">
                        <small>
                          <strong>{fileName || "Choisissez le fichier"}</strong>
                        </small>
                      </label>
                    </div>
                  </div>
                </div>
                {/* Dropdown pour la sélection de la résolution */}
                <div className="form-group">
                  <label htmlFor="resolution">
                    <small>
                      <strong>Résolution&nbsp;:</strong>
                    </small>
                  </label>
                  <select
                    id="resolution"
                    className="form-control form-control-sm"
                    value={resolution}
                    onChange={(e) => setResolution(e.target.value)}
                  >
                    <option value="">Format original</option>
                    <option value="426x240">426 x 240 (240p - SD)</option>
                    <option value="640x360">640 x 360 (360p - SD)</option>
                    <option value="854x480">854 x 480 (480p - SD)</option>
                    <option value="1280x720">1280 x 720 (720p - HD)</option>
                    <option value="1920x1080">
                      1920 x 1080 (1080p - Full HD)
                    </option>
                    <option value="2560x1440">2560 x 1440 (1440p - 2K)</option>
                    <option value="3840x2160">3840 x 2160 (2160p - 4K)</option>
                    <option value="7680x4320">7680 x 4320 (4320p - 8K)</option>
                  </select>
                </div>
                <div className="progress mt-3 mb-3" style={{ height: "25px" }}>
                  <div
                    id="progressBar"
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    style={{ width: `${progress}%` }}
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {progress}%
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-warning btn-sm w-100"
                  disabled={loading}
                >
                  <strong>{loading ? "Importation..." : "Importer"}</strong>
                </button>
              </form>
              {isUploadComplete && (
                <div className="alert alert-success mt-3">
                  Vidéo uploadée avec succès ! Fichier: {fileName}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {/* Modal pour avertissement de projet invalide */}
      {invalidProjectModalVisible && (
        <div
          className="modal"
          style={{ display: "block", background: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title">
                  <i className="fas fa-exclamation-triangle"></i>&nbsp;Nom de
                  projet invalide
                </h6>
                <button
                  type="button"
                  className="close"
                  onClick={closeInvalidProjectModal}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <small>
                    <strong>
                      Le nom du projet ne doit contenir que des lettres, des
                      chiffres, des tirets ou des underscores, et ne doit pas
                      contenir d'espaces ou de caractères spéciaux.
                    </strong>
                  </small>
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-warning btn-sm"
                  onClick={closeInvalidProjectModal}
                >
                  <small>
                    <strong>
                      <i className="fas fa-times-circle"></i>&nbsp;Fermer
                    </strong>
                  </small>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Modal d'avertissement */}
      {showModal && (
        <div
          className="modal"
          style={{ display: "block", background: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title">
                  <i className="fas fa-exclamation-triangle"></i>&nbsp; Projet
                  déjà existant
                </h6>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowModal(false)}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <small>
                    <strong>
                      Un projet avec ce nom existe déjà. Voulez-vous l'écraser ?
                    </strong>
                  </small>
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  onClick={resetForm}
                >
                  <small>
                    <strong>Annuler</strong>
                  </small>
                </button>
                <button
                  type="button"
                  className="btn btn-warning btn-sm"
                  onClick={handleOverwriteConfirm}
                >
                  <small>
                    <strong>Écraser</strong>
                  </small>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal d'avertissement pour les scènes */}
      {warningModalVisible && (
        <div
          className="modal"
          style={{ display: "block", background: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Avertissement</h5>
                <button
                  type="button"
                  className="close"
                  onClick={closeNoScenesWarning} // Modifié ici
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>{warningMessage}</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={closeNoScenesWarning} // Modifié ici
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadVideo;
