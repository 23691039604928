import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

const TableVideos = ({ projectName, setLoading }) => {
  const API_URL = process.env.REACT_APP_API_HOST;
  const [videos, setVideos] = useState([]);
  const [error, setError] = useState(null);

  // Fonction pour récupérer les données vidéo
  const fetchData = useCallback(async () => {
    setLoading(true); // Déclenche le chargement dans Home
    try {
      const response = await fetch(
        `${API_URL}/getVideosByProjectName.php?projectName=${projectName}`
      );
      const data = await response.json();
      if (data.error) {
        console.error(data.error);
        setError(data.error);
        return;
      }
      setVideos(data.videos);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message);
    } finally {
      setLoading(false); // Arrête le chargement dans Home
    }
  }, [API_URL, projectName, setLoading]);

  useEffect(() => {
    if (projectName) {
      fetchData();
    }
  }, [fetchData, projectName]);

  return (
    <div>
      <div className="card card-warning">
        <div className="card-header">
          <strong>Fichiers vidéo recadrés (projet : {projectName})</strong>
          <div className="card-tools">
            <button
              type="button"
              className="btn btn-tool"
              data-card-widget="collapse"
              title="Collapse"
            >
              <i className="fas fa-minus" />
            </button>
          </div>
        </div>
        <div className="card-body p-0">
          {error && (
            <div className="alert alert-danger text-center">{error}</div>
          )}
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>
                    <strong>Nom du fichier</strong>
                  </th>
                  <th>
                    <strong>Durée</strong>
                  </th>
                  <th>
                    <strong>Codec</strong>
                  </th>
                  <th>
                    <strong>Résolution</strong>
                  </th>
                  <th>
                    <strong>Bitrate</strong>
                  </th>
                  <th>
                    <strong>Taille</strong>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {videos.length > 0 ? (
                  videos.map((video) => (
                    <tr key={video.name}>
                      <td>
                        <i className="fas fa-file-video"></i>&nbsp;&nbsp;
                        <small>{video.name}</small>
                      </td>
                      <td>
                        <small>{video.duration}</small>
                      </td>
                      <td>
                        <small>{video.codec}</small>
                      </td>
                      <td>
                        <small>{video.resolution}</small>
                      </td>
                      <td>
                        <small>{video.bitrate}</small>
                      </td>
                      <td>
                        <small>{video.size}</small>
                      </td>
                      <td className="text-right py-0 align-middle">
                        <div className="btn-group btn-group-sm">
                          {/* Boutons de visionnage ou suppression */}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center">
                      <small>
                        <strong>Chargement...</strong>
                      </small>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

// Définir les types de props attendus
TableVideos.propTypes = {
  projectName: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired, // Propriété requise pour setLoading
};

export default TableVideos;
